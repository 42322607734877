import { get } from './http'

export function getCollege() {
  // 学院简介
  return get('/api/singlepage/intro')
}

export function getTrain(page) {
  // 培训动态
  return get(`/api/news/train?page=${page}&pagesize=9`)
}
