<!-- 培训动态 -->
<template>
  <div class="mt-80-mb-140">
    <div class="container">
      <div class="row">
        <div
          class="news-item col-lg-4 col-md-4 col-sm-6 col-6 px-2"
          v-for="item in trainList"
          :key="item"
          @click.capture="goToDetail(item.newsid)"
        >
          <div class="item-content">
            <div class="img-box">
              <img :src="item.image" class="news-img" />
              <div class="time">{{ item.create_at }}</div>
            </div>
            <h5>{{ item.title }}</h5>
            <p>
              {{ item.desc }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <PaginationBox
      :total="pageObj.total_count"
      v-model:currentPage="currentPage"
    ></PaginationBox>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router'
import PaginationBox from '@/components/PaginationBox'
import { onMounted, ref, watch } from 'vue'
import { getTrain } from '../../../server/college'

const router = useRouter()

const goToDetail = (newsid) => {
  router.push({
    path: '/HuaduCollege/TrainDetail',
    query: { newsid: newsid }
  })
}

const trainList = ref([])
const pageObj = ref({})
const currentPage = ref(1)

onMounted(async () => {
  fetchTrain()
})

watch(currentPage, async () => {
  fetchTrain()
})

const fetchTrain = async () => {
  const r = await getTrain(currentPage.value, 2)
  trainList.value = r.list
  pageObj.value = r.cur_page
}
</script>
<style lang="stylus" scoped>
.news-item
  .item-content
    background #fff
    margin-bottom 30px
    height 420px
    border-radius: 5px;
    overflow hidden
    @media screen and (max-width 768px) {
      height 260px
    }
    .img-box
      position relative
      width 100%
      height 200px
      background #C2C2C2
      overflow hidden
      @media screen and (max-width 768px) {
        height 150px
      }
      &::after
        content: "";
        width: 100%;
        height: 0%;
        background-color: rgba(0,0,0,0.4);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0;
        transition: all 0.5s ease-in-out 0s;
      .time
        position absolute
        left 5%
        bottom 0
        width: 120px;
        height: 40px;
        background: #209BD7;
        opacity: 0.5;
        color #fff
        text-align center
        line-height 40px
        font-size: 16px;
        font-family: Source Han Sans CN;
      img
        width 100%
        height 100%
        object-fit contain
        transition: all 0.5s ease-in-out 0s;
    & > h5
      margin 30px 0 10px
      padding 0 40px
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      overflow hidden
      text-overflow ellipsis
      word-break break-all
      white-space nowrap
      width 100%
      @media screen and (max-width 768px) {
        margin 5px 0
        padding 0 10px
        font-size: 18px;
      }
    & > p
      padding 0 40px
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #999999;
      display -webkit-box
      overflow hidden
      text-overflow ellipsis
      -webkit-box-orient vertical
      -webkit-line-clamp 3
      @media screen and (max-width 768px) {
        padding 0 10px
        font-size: 14px;
      }
  &:hover
    .news-img
      transform scale(1.2)
    .img-box
      &::after
        height 100%
        opacity 1
.pagination
  a
    cursor pointer
</style>
